* {
  margin: 0;
  padding: 0;
}
body {
  height: 100vh;
}
.hide-resize-observer {
  display: flex !important;
}
